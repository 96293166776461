/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/global/reset.css';

const addScript = url => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    // this script loading is required with React useEfect usage
    // - either for prod build process, on navigating with reach-router in rehidrated Gatsby mode
    // - or in dev mode, when we have everything dynamic
    addScript('https://widget.clutch.co/static/js/widget.js');
  };
};

// You can delete this file if you're not using it
